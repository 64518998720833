<template>
    <div class="pay h-font-lg" id="pay">
        <div class="pay-title">
            <span>支付明细</span>
        </div>
        <div class="compulsory h-group">
            <div class="h-cell__content">
                <van-row>
                    <van-col span="6"  class="h-text-color_gray-6">保险公司</van-col>
                    <van-col span="18">{{ payForm.insuredOrgName }}</van-col>
                </van-row>
                <van-row>
                    <van-col span="6"  class="h-text-color_gray-6">车架号</van-col>
                    <van-col span="18">{{ payForm.frameNo }}</van-col>
                </van-row>
                <van-row>
                    <van-col span="6"  class="h-text-color_gray-6">车主姓名</van-col>
                    <van-col span="18">{{ payForm.ownerName }}</van-col>
                </van-row>
                <van-row>
                    <van-col span="6"  class="h-text-color_gray-6">被保人名称</van-col>
                    <van-col span="18">{{ payForm.insuredName }}</van-col>
                </van-row>
                <van-row>
                    <van-col span="6"  class="h-text-color_gray-6">投保人名称</van-col>
                    <van-col span="18">{{ payForm.holderName }}</van-col>
                </van-row>
                <van-row>
                    <van-col span="6"  class="h-text-color_gray-6">交强险保费</van-col>
                    <van-col span="18">{{ payForm.compulsoryActualPremium | amountFilter }}</van-col>
                </van-row>
                <van-row>
                    <van-col span="6"  class="h-text-color_gray-6">商业险保费</van-col>
                    <van-col span="18">{{ payForm.commercialActualPremium }}</van-col>
                </van-row>
                <van-row>
                    <van-col span="6"  class="h-text-color_gray-6">车船税</van-col>
                    <van-col span="18">{{ payForm.compulsoryVehicleTaxActualPremium | amountFilter }}</van-col>
                </van-row>
                <van-row>
                    <van-col span="6"  class="h-text-color_gray-6">支付总金额</van-col>
                    <van-col span="18">{{ payForm.totalPremium | amountFilter }}</van-col>
                </van-row>
            </div>
        </div>
        
        <div class="quote-result_bnt">
            <div class="quote-result_bnt--fixed success">
                <van-button v-if="!payStart" type="primary" style="margin-right: 10px;" block round @click="payConfirm">确认付款</van-button>
                <van-button v-else type="primary" style="width: 130px; margin-right: 10px;" block round @click="toPage">我已完成支付</van-button>
                <van-button type="default" style="border: 1px solid rgb(87, 204, 194); color: rgb(87, 204, 194);" block round @click="back">暂不支付</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { request } from "@/api/service";
import dayjs from 'dayjs'
import { Notify, Toast } from 'vant'
export default {
  name: 'pay',
  data() {
    return {
        paramsData: {
            basis: {},
            commercial: {},
            compulsory: {}
        },
        payLoading: false,
        payForm: {},
        payApi: null,
        payStart: false
    }
  },
  filters: {
    amountFilter(value) {
      if (value) {
        return parseFloat(value).toFixed(2)
      }
      return '0.00'
    }
  },
  computed: {
    ...mapState("car", ["quote","quoteRes"]),
    ...mapState("user", ["info"])
  },
  async created() {
  },
  mounted() {
    pay.style.height = (document.documentElement.clientHeight - 60) + 'px'
    this.paramsData = this.quoteRes[this.quote.basis.insuredOrgCode]
    this.getInfo(this.paramsData.basis.subBusinessNo)
  },
  methods: {
    dayjs,
    back () {
        this.$router.go(-1)
    },
    payConfirm () {
        let setInter = setInterval(() => {
            Toast.loading({
            forbidClick: true
            }, 500)
        })
        this.payStart = false
        this.payApi = null
        request({
            url: '/afis-api-manage/invoke/core/auto/paymentApplyIn',
            method: 'post',
            data: {
                subBusinessNo: this.paramsData.basis.subBusinessNo
            }
        }).then(res => {
            this.payStart = true
            this.payApi = res.payUrl
            location.href = this.payApi
            clearInterval(setInter)
            Toast.clear()
        }).catch(e => {
            this.payStart = false
            clearInterval(setInter)
            Toast.clear()
        })
    },
    getInfo (subBusinessNo) {
        request({
            url: '/afis-auto-web/auto/policy/query',
            method: 'post',
            data: {
                subBusinessNo,
                paymentType: '1'
            }
        }).then(res => {
            this.payForm = {
                subBusinessNo,
                insuredOrgName: res.basis.insuredOrgName,
                frameNo: res.vehicle.frameNo,
                compulsoryActualPremium: res.compulsory
                    ? res.compulsory.actualPremium
                    : 0,
                ownerName: res.owner.name,
                compulsoryVehicleTaxActualPremium: res.compulsory
                    ? res.compulsory.vehicleTax.vehicleTaxPremium
                    : 0,
                holderName: res.holder.name,
                commercialActualPremium: res.commercial
                    ? res.commercial.actualPremium
                    : 0,
                insuredName: res.insured.name,
                totalPremium: res.basis.totalActualPremium,
                insuredOrgCode: res.basis.insuredOrgCode
            }
        })
    },
    toPage () {
        this.$router.push({
            name: 'QuoteList'
        })
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.pay {
    background: url('./image/bg-title.png') no-repeat fixed;
    background-size: 100% 100%;
    padding: 0 8px;
    .pay-title {
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        padding: 14px 0 20px 0px;
        .van-icon {
            font-weight: bold;
            margin-left: 6px;
            position: relative;
            top: 2px;
        }
    }
    .h-cell__content{
        padding: 6px 8px;
        font-size: $font-size-sm;
        ::v-deep .van-row {
            padding:9px 0;
        }
    }
    .h-cell__title{
        font-weight: $font-weight-bold-lg;
        font-size: $font-size-md;
        span.icon {
            border-radius: $border-radius-sm;
            color: $white;
            padding: 3px;
            margin-right:6px;
        }
    }
    .h-cell{
        padding: 10px 8px;
    }
    .h-cell__value {
        color: #0066FF;
        font-size: 12px;
    }
    .base{
        .warn{
            text-indent: -4px;
            padding-left: 12px;
            font-size: $font-size-xs;
            color: $primary-color;
        }
    }
    .h-group{
        margin-bottom: 10px;
        padding: 8px;
        border-radius: $border-radius-lg;
    }
    .car-des {
        .refresh-des {
            margin-right: 4px;
        }
        .van-icon {
            font-size: 14px;
        }
    }
    .insured-msg {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .quote-result_bnt {
        height: 60px;
        .success {
            text-align: center;
            button {
                width: 40%;
            }
        }
    }
    .quote-result_bnt--fixed {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 99;
        background-color: #fff;
        padding: 10px;
        border-top: #f7f8fa solid 2px;
        button {
            width: 100px;
            display: inline-block;
        }
    }
    .van-cell {
        padding-bottom: 0;
        padding-top: 0;
    }
}
</style>
